<template>
  <div class="container px-5 mx-auto md:max-w-5xl lg:max-w-7xl">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div
        class="flex flex-col items-center justify-center order-2 my-5 space-y-6 md:order-1 md:space-y-10 md:items-baseline md:my-0"
      >
        <img src="@/assets/login-obj.png" class="max-w-full" />
      </div>
      <div
        class="flex flex-col items-center justify-center order-1 my-5 space-y-6 md:order-2 md:space-y-8 md:my-0"
      >
        <router-link to="/" class="text-5xl font-bold">
          <SiteLogo></SiteLogo>
        </router-link>
        <!-- <h3 class="mt-5 text-2xl font-bold md:text-3xl">
          Your email has been verified successfully. Please login here. <router-link class="text-custom-green-dark" to="/login"> Click here to login</router-link>
        </h3> -->
        <h3 class="mt-5 text-2xl font-bold md:text-3xl">
          Your email has been verified successfully.
        </h3>
        <div class="w-20 h-2 bg-gray-900"></div>
        <div class="flex w-full max-w-lg">
          <div class="flex flex-col flex-1">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteLogo from '@/components/SiteLogo'
export default {
  components: {
    SiteLogo
  },
  setup () {
    return {}
  }
}
</script>
